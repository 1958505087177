import React  from "react"
import {injectIntl } from "gatsby-plugin-intl"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



const SliderBanner = ({images,slide}) => {

    return (
            <Carousel
            autoPlay
            infiniteLoop
            interval={5000}
            showStatus={false}
            showThumbs={false}
            emulateTouch={true}>
                {
                    images === null ?  images.map((img,i)=>
                    <div className="img-banner-container" key={i}>
                         <img src={img} className="img-banner"  />
                    
                     </div>
                    ) :
                      <div className="img-banner-container">
                        <img src={slide} className="img-banner" />
                    </div>
                    
                   
                }
               
               
              
               
            </Carousel>
        );
   
}

export default injectIntl(SliderBanner)