import React, { Component } from "react"
import { injectIntl } from "gatsby-plugin-intl"



class LivingBeing extends Component {
    constructor(props) {
        super(props);

    }

  /********** get selected type name  **********/
  getSelectedTypeName(id) {
    const type = this.props.LivingSelectedType.map(type => type )
      if (!this.props.is_leaf) {
        for (let index = 0; index < type.length; index++) {
            const element = type[index];
            const leaf = this.props.selectedTypeLeaf.map(el => el )
            if (element.id === leaf[0].parent) {
              return element.name
            }
        }
      
      }else{
       const type = this.props.LivingSelectedType.filter(type => type.id === id);
       const name = type[0].name;
       return name 
}

}
    render() {
        const isArabicLocale = this.props.intl.locale === "ar";

        return (


            <>
      {this.props.living.map(item =>
        <tr  key={item.id}>
          <td>{item.common_name_ar}</td>
          <td>{item.common_name_en}</td>
          <td>{item.local_name}</td>
          <td>{item.scientific_name}</td>
          <td><div  className="type-img" style={{ backgroundImage: `url(${item.image})` }}></div></td>
        </tr>
         )}
    
              

              



            </>
                                                       

        )
    }

}



export default injectIntl(LivingBeing)
