import React, { Component } from "react"
import { connect } from "react-redux"
import { Container,TabContent, TabPane, Nav, NavItem, NavLink,Row,Col,Table } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import classnames from 'classnames';

import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"

import slide from "../images/wildlife/bannerImgs/background.jpg"
import SliderBanner from "../components/sliderBanner"
import LivingBeing from "../components/agreementDetails/livingBeing" 




class SingleType extends Component {

   
    state = {
        region: null,
        regionData: {},
        livingSelectTypes: [],
        selectedTypeLeaf:[],
        livingBeing: [],
        livingBeingTypes: [],
        allBeingLeaf:[],
        selectedType: 0,
        isLoading: true,
        is_leaf:true,
        type: 0,
        activeTab:'0',
        activeType:'',
        show: false,
        leaf_id:''    
    }

    componentDidMount() {       
        var livigTypeUrlParam  = new URL(this.props.location.href).searchParams.get("livingType"),
            activeTypeTab =  new URL(this.props.location.href).searchParams.get("active")
        this.setState({
            selectedType: livigTypeUrlParam,
            activeType: activeTypeTab
        }, () => { this.getLivingSelectedTypes(); this.getLivingBeingTypes(); this.getAllLivingBeing() })
    
        
    }
    componentDidUpdate(prevProps) {
         if (this.props.regions !== prevProps.regions ||  this.props.selectedRegionId !== prevProps.selectedRegionId) {
           this.setState({
               type: 0,
               show: false
           })   
           this.getAllLivingBeing()
        }
    }
 
   /************ fetch 4 items from API where Their parent === null  ***********/
    getLivingBeingTypes() {
        var url;
        if (this.state.activeType === '1') {
            url ='agreements_livingbeing_types'
        }else{
            url = 'agreements_redlistsbeing_types'
        }
        axios(this.props.intl.locale, `${url}/?no_parent=${true}`)
        .then(response => {
            const sortLivingTypes = (response.data).sort(function(a, b){return b.order > a.order});
            this.setState({
                livingBeingTypes: sortLivingTypes,
                isLoading: false
            });
        })
    }
   /************ fetch  Selects from API ِAccording to  Their parent (radio box)  ***********/

    getLivingSelectedTypes() {
        var url;
        if (this.state.activeType === '1') {
            url ='agreements_livingbeing_types'
        }else{
            url = 'agreements_redlistsbeing_types'
        }
        axios(this.props.intl.locale, `${url}/?parent=${this.state.selectedType}`)
        .then(response => {
            /****** sort data *****/
            const sortLivingTypes = (response.data).sort(function(a, b){return b.order > a.order});
                this.setState({
                    livingSelectTypes: sortLivingTypes,
                   
                });      
                sortLivingTypes.map(leaf=>{
                    if ((sortLivingTypes.length === leaf.order) && (leaf.is_leaf === false)) {
                        this.setState({show:false})
                    }else{
                        this.setState({is_leaf: leaf.is_leaf})

                    }
                }) 
        })
    }
   /************ fetch  type   from API ِAccording to  type of select (radio box)  ***********/
    getLivingBeingById(id) {
        const region = this.props.regions.find(region => region.code === this.props.selectedRegionCode)
        var url;
        if (this.state.activeType === '1') {
            url ='agreements_livingbeing'
        }else{
            url = 'agreements_redlistsbeing'
        }
        axios(this.props.intl.locale, `${url}/?type=${id}&region=${region.id}`)
        .then(response => {
            const beings = [];
                response.data.map(being => {
                return beings.push(being)
            })
            this.setState({
                livingBeing: beings
            });
        })
    }
    /************ get all living being when tab is select all **************/
    getAllLivingLeaf(){
        const region = this.props.regions.find(region => region.code === this.props.selectedRegionCode)
        const beings = []
        var url;
        if (this.state.activeType === '1') {
            url ='agreements_livingbeing'
        }else{
            url = 'agreements_redlistsbeing'
        }
         this.state.selectedTypeLeaf.map(leaf=>{
            axios(this.props.intl.locale, `${url}/?type=${leaf.id}&region=${region.id}`)
            .then(response => {
               const living = response.data
               living.length > 0 && beings.push(living)
                this.setState({allBeingLeaf: beings})
           
            }) 
           
        })
    }
   /************ fetch  all types   from API ِ  ***********/
    getAllLivingBeing() {
        this.setState({allBeingLeaf:[],livingBeing:[]})
        if(!this.props.regions.length || !this.props.selectedRegionId) return;
        const region = this.props.regions.find(region => region.code === this.props.selectedRegionCode)
        this.setState({
            region: region
        });  
        var url;
        if (this.state.activeType === '1') {
            url ='agreements_livingbeing'
        }else{
            url = 'agreements_redlistsbeing'
        }
        
            axios(this.props.intl.locale, `${url}/?region=${region.id}`)
            .then(response => {
                this.setState({
    
                    livingBeing : response.data
                })
            })  
    }
    /************** get name for one of 4 items when select it***************/
    getLivingTypeName(){
        if (!this.state.isLoading) {
            
            const type =  this.state.livingBeingTypes.filter(type=> type.id == this.state.selectedType);
            if (type.length > 0) {
                const name = type[0].name;
                  return name
            }else{
            return
            }
          
        }
      
    }

  /******* function for handle check input box  **********/  
    handleChanged (e) {
        this.setState({
         type: e.target.value
         }); 
     }
   /************  ************* */
    handelLivingBeing(id,leaf){
        this.setState({show: false,allBeingLeaf:[]})
            if (!leaf) {
                var url;
                if (this.state.activeType === '1') {
                    url ='agreements_livingbeing_types'
                }else{
                    url = 'agreements_redlistsbeing_types'
                }
                axios(this.props.intl.locale, `${url}/?parent=${id}`)
                .then(response => {
                        this.setState({
                            selectedTypeLeaf: (response.data).sort(function(a, b){return b.order > a.order}),
                            show: true,
                            leaf_id: id,
                            activeTab:'0'
                        });
                        this.getAllLivingLeaf()

                })
            }else{
                this.getLivingBeingById(id);
                this.setState({
                    type: id,
                })   
            }
           
    }

    /******** function for living being types (4 main items)  ********/
    handleSelectType(id) {
         navigate("/singleType/?livingType=" + id + "&country="+ this.props.selectedRegionCode + "&active="  + this.state.activeType )
        this.setState({ selectedType: id,type: 0,show:false,allBeingLeaf:[] }, () => { this.getLivingSelectedTypes();this.getAllLivingBeing();})
            
    }
  /*********** fetch All Living Being According to their Parent *************/
  getLivingByParent(){
    const livingBeings = [];
       this.state.livingSelectTypes.map(el => {
           if (this.state.is_leaf) {
                 const livings = this.state.livingBeing.filter(live => live.type === el.id)
                 livings.length > 0 && livingBeings.push(livings) 
           }
              
        })
    return livingBeings
  }
  /************* function for biodiversity and red list tabs*************/
  toggleType (tab){
      
    if(this.state.activeTab !== tab) {
        this.setState({activeType:tab},()=> {this.getLivingBeingTypes();this.getLivingSelectedTypes()})      
       
    }   
}
 /******** function for tabs  *********/
  toggle (tab){
        if (tab === '0') {
            this.getAllLivingLeaf()
        }    
        if(this.state.activeTab !== tab) {
            this.setState({activeTab:tab})      
            this.getLivingBeingById(tab)
        }   
  }
    render() {

        const isArabicLocale = this.props.intl.locale === "ar";
        const { livingBeingTypes,livingSelectTypes,livingBeing ,selectedType,allBeingLeaf,is_leaf,selectedTypeLeaf,activeTab} = this.state
     
        return (
            <Layout>
                <SEO title="Agreements" />
                <div className="main">

                    <div className="section text-center agreement-details">

                        <div className="banner">
                            <SliderBanner slide={slide} />
                            <div className="fixed banner-icons single-page-icons">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeType === '1' })}
                                            onClick={() => { this.toggleType('1') }}
                                        >
                                            <FormattedMessage id="biodiversity" />

                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeType === '2' })}
                                            onClick={() => { this.toggleType('2') }}
                                        >
                                            <FormattedMessage id="red_lists" />

                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeType}>
                                    <TabPane tabId="1">
                                        <div className="icons-box single-type" >
                                            {
                                                livingBeingTypes && livingBeingTypes.map(type =>

                                                    <div className="flip-card" key={type.id} onClick={() => this.handleSelectType(type.id)}>

                                                        <div >
                                                            <div className="inner">
                                                                <div className="img-inner hvr-grow  " style={{ backgroundImage: `url(${type.image})`, borderColor: type.id == selectedType ? "#2ac3ef" : "#a7abad" }}></div>
                                                            </div>
                                                            <div className="back">
                                                                <h6 style={{ color: type.id == selectedType ? "#2ac3ef" : "" }}>{type.name}</h6>
                                                            </div>
                                                        </div>

                                                    </div>


                                                )
                                            }


                                        </div >
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="icons-box single-type" >
                                            {
                                               livingBeingTypes && livingBeingTypes.map(type =>

                                                    <div className="flip-card" key={type.id} onClick={() => this.handleSelectType(type.id)}>

                                                        <div >
                                                            <div className="inner">
                                                                <div className="img-inner hvr-grow  " style={{ backgroundImage: `url(${type.image})`, borderColor: type.id == selectedType ? "#2ac3ef" : "#a7abad" }}></div>
                                                            </div>
                                                            <div className="back">
                                                                <h6 style={{ color: type.id == selectedType ? "#2ac3ef" : "" }}>{type.name}</h6>
                                                            </div>
                                                        </div>

                                                    </div>


                                                )
                                            }


                                        </div >
                                    </TabPane>
                                </TabContent>


                            </div>
                        </div>

                        <div className={isArabicLocale ? "text-lg-right title-agreement" : "text-lg-left title-agreement"}>
                            <h3>
                                 <FormattedMessage id="types" /> {this.getLivingTypeName()}
                                {"   -   "}
                            </h3>
                            <h3 >
                                <div className="d-inline-block">
                                {this.state.region && this.state.region.name}
                                </div>
                                <div className="d-inline-block mr-2 ml-2"> 
                                {this.state.region && <img src={this.state.region.image}
                                    style={{ width: "100%", maxWidth: "50px", height: "auto" }} alt="img" />}
                                </div>
                                
                                        
                                    
                            </h3>
                           

                        </div>
                        <div className="check-boxs">
                            <Container>
                                <div className="living-types">
                                    <div className="box">
                                        <label className={isArabicLocale ? "text-right" : "text-left"} ><FormattedMessage id="choose_type" /></label>
                                        <ul className={isArabicLocale ? "style-left" : "style-right"}>
                                            {(is_leaf && livingSelectTypes.length > 0) &&
                                            <li>
                                                <label className={classnames({active: this.state.type == 0})}>
                                                <input onClick={() => this.getAllLivingBeing()} type="radio" name="types" checked={this.state.type == 0} value={"0"} onChange={(e) => this.handleChanged(e)} />
                                                <FormattedMessage id="all" /> 
                                                </label>
                                             
                                               </li>}
                                            {
                                                livingSelectTypes && livingSelectTypes.map(select =>
                                                <li key={select.id} ><label className={classnames({active: this.state.type == select.id})}><input onClick={() => this.handelLivingBeing(select.id, select.is_leaf)} checked={this.state.type == select.id} type="radio" onChange={(e) => this.handleChanged(e)} name="types" value={select.id}/> {select.name}</label> 
                                                 {(this.state.show && this.state.type == select.id) &&
                                            
                                            <Nav tabs>
                                                <NavItem onClick={() => { this.toggle('0'); }}>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '0' })}

                                                    >
                                                        <FormattedMessage id="all" />
                                                    </NavLink>
                                                </NavItem>
                                                {selectedTypeLeaf.map(leaf =>
                                                    <NavItem key={leaf.id} onClick={() => { this.toggle(leaf.id); }}>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === leaf.id })}

                                                        >
                                                            {leaf.name}
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </Nav>
                                            }
                                                </li>
                                                 )
                                                 
                                            }
                                        </ul>
                                       
                                    </div>
                                    <div className={isArabicLocale ? "livingBeing pr-3" : "livingBeing pl-3"}>
                                        {this.state.show ?
                                            <>
                                              
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId='0'>
                                                        <Table striped responsive>
                                                            <thead>
                                                                <tr>

                                                                
                                                                    <th><FormattedMessage id="common_name_ar" /></th>
                                                                    <th><FormattedMessage id="common_name_en" /></th>
                                                                    <th><FormattedMessage id="local_name" /></th>
                                                                    <th><FormattedMessage id="the_scientific_name" /></th>
                                                                    <th><FormattedMessage id="image" /></th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {allBeingLeaf.length > 0 ?
                                                                    allBeingLeaf.map((beingLeaf, i) =>
                                                                        <LivingBeing key={i} living={beingLeaf} LivingSelectedType={livingSelectTypes} selectedTypeLeaf={selectedTypeLeaf} is_leaf={is_leaf} />

                                                                    )
                                                                    :
                                                                    <tr >
                                                                        <td className="no-data-msg" colSpan="5">
                                                                            <FormattedMessage id="no_data_found" />
                                                                        </td>
                                                                    </tr>
                                                                  

                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </TabPane>
                                                    {selectedTypeLeaf.map(leaf =>
                                                        <TabPane tabId={leaf.id} key={leaf.id}>
                                                            <Table striped responsive>
                                                                <thead>
                                                                    <tr>

                                                                        
                                                                        <th><FormattedMessage id="common_name_ar" /></th>
                                                                        <th><FormattedMessage id="common_name_en" /></th>
                                                                        <th><FormattedMessage id="local_name" /></th>
                                                                        <th><FormattedMessage id="the_scientific_name" /></th>
                                                                        <th><FormattedMessage id="image" /></th>



                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {livingBeing.length > 0 ?

                                                                        <LivingBeing living={livingBeing} LivingSelectedType={livingSelectTypes} selectedTypeLeaf={selectedTypeLeaf} is_leaf={is_leaf} />
                                                                        :
                                                                        <tr >
                                                                            <td className="no-data-msg" colSpan="5" >
                                                                                <FormattedMessage id="no_data_found" />
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </TabPane>
                                                    )}


                                                </TabContent>
                                            </>
                                            
                                            : 
                                            <Table striped responsive>
                                                <thead>
                                                    <tr>

                                                        <th><FormattedMessage id="common_name_ar" /></th>
                                                        <th><FormattedMessage id="common_name_en" /></th>
                                                        <th><FormattedMessage id="local_name" /></th>
                                                        <th><FormattedMessage id="the_scientific_name" /></th>
                                                        <th><FormattedMessage id="image" /></th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        livingBeing.length > 0 ?
                                                            this.getLivingByParent().length > 0 ?
                                                                this.getLivingByParent().map((living, i) => <LivingBeing key={i} living={living} LivingSelectedType={livingSelectTypes} is_leaf={is_leaf} />)
                                                                :
                                                                <tr >
                                                                    <td className="no-data-msg" colSpan="5" >
                                                                        <FormattedMessage id="no_data_found" />
                                                                    </td>
                                                                </tr>
                                                            :
                                                            <tr >
                                                                <td className="no-data-msg" colSpan="5" >
                                                                    <FormattedMessage id="no_data_found"  />
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </Table>


                                        }

                                        
                                    </div>
                                </div>
                            </Container>
                           
                        </div>
                      
                           
                    </div>
                </div>
            </Layout >
        )
    }
}

const mapStateToProps = state => {
    return {
        regions: state.regions.regions,
        selectedRegionId: state.regions.selectedRegion && state.regions.selectedRegion.id,
        selectedRegionCode: state.regions.selectedRegion && state.regions.selectedRegion.code,
    }
}

export default connect(mapStateToProps)(injectIntl(SingleType))
